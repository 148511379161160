<template>
  <div>
    <vb-lockscreen />
  </div>
</template>
<script>
import VbLockscreen from '@/@vb/components/Auth/Lockscreen'
export default {
  components: {
    VbLockscreen,
  },
}
</script>
